import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '.';
import { CountryReport } from '../types';

// Define the initial state using that type
const initialState = {
  countryReports: [] as CountryReport[],
};

export const databaseSlice = createSlice({
  name: 'database',
  initialState,
  reducers: {
    setCountryReports: (state, action: PayloadAction<CountryReport[]>) => {
      state.countryReports = action.payload;
    },
  },
});

export const { setCountryReports } = databaseSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCountryReports = (state: RootState) =>
  state.database.countryReports;

export const databaseReducer = databaseSlice.reducer;
