import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '.';

// Define the initial state using that type
const initialState = {
  locale: undefined as string | undefined,
  defaultLocale: undefined as string | undefined,
};

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    setDefaultLocale: (state, action: PayloadAction<string>) => {
      state.defaultLocale = action.payload;
    },
  },
});

export const { setLocale, setDefaultLocale } = i18nSlice.actions;

export const selectLocale = (state: RootState) => state.i18n.locale;
export const selectDefaultLocale = (state: RootState) =>
  state.i18n.defaultLocale;

export const i18nReducer = i18nSlice.reducer;
