import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { databaseReducer } from './database';
import { i18nReducer } from './i18n';

export const createStore = () =>
  configureStore({
    reducer: {
      database: databaseReducer,
      i18n: i18nReducer,
    },
  });

export type AppDispatch = ReturnType<typeof createStore>['dispatch'];
export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
