import { colors, createTheme, responsiveFontSizes } from '@mui/material';
import { grey } from '@mui/material/colors';

export const colorRed = '#C90813';
export const colorBlue = '#B6E2DE';
export const colorBlueLight = '#daf0ee';

export const fontSizeBase = 19.3;

const theme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 800,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: 'Torrent',
      fontSize: fontSizeBase,
      body1: {
        color: colors.common.black,
      },
      h1: {
        fontFamily: 'Calibre',
        fontWeight: 900,
        fontSize: fontSizeBase * 2.25,
        textTransform: 'uppercase',
        lineHeight: 1,
        color: colorRed,
      },
      h2: {
        fontFamily: 'Calibre',
        fontWeight: 900,
        textTransform: 'uppercase',
        fontSize: fontSizeBase * 1.5,
      },
      h3: {
        fontFamily: 'Calibre',
        fontWeight: 900,
        textTransform: 'uppercase',
        fontSize: fontSizeBase * 1.3,
      },
      h4: {
        fontFamily: 'Calibre',
        fontWeight: 900,
        textTransform: 'uppercase',
        fontSize: fontSizeBase * 1.2,
      },
      h5: {
        fontFamily: 'Calibre',
        fontWeight: 700,
        textTransform: 'uppercase',
        fontSize: fontSizeBase * 1.125,
      },
      h6: {
        fontFamily: 'Calibre',
        fontWeight: 700,
        textTransform: 'uppercase',
        fontSize: fontSizeBase * 1.125,
      },
    },
    palette: {
      primary: {
        light: grey['700'],
        main: grey['800'],
        dark: grey['900'],
      },
      error: {
        light: colorRed,
        main: colorRed,
        dark: colorRed,
      },
      info: {
        light: colorBlueLight,
        main: colorBlue,
        dark: colorBlue,
      },
    },
  })
);

export default theme;
